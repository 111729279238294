import {Experiment, Result} from "@growthbook/growthbook";

export default function getGrowthbookConfig(gbuuid?: string) {
    return {
        apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
        clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
        decryptionKey: process.env.NEXT_PUBLIC_GROWTHBOOK_DECRYPTION_KEY,
        enableDevMode: true,
        trackingCallback: (experiment: Experiment<any>, result: Result<any>) => {
            if (window.gtagConsent) {
                window.gtagConsent(
                    'event',
                    'experiment_viewed',
                    {
                        event_category: 'experiment',
                        experiment_id: experiment.key,
                        variation_id: result.variationId,
                        gb_user_id: gbuuid,
                        anonymous_id: gbuuid,
                        user_id: gbuuid,
                        user_pseudo_id: gbuuid,
                    },
                )
            }
        }
    }
}
