"use client"
import {createContext, useContext, useState, useEffect, ReactNode, SetStateAction} from 'react';

interface ITrackingContext {
    isGtagLoaded: boolean;
    setIsGtagLoaded: SetStateAction<any>;
    beforeScriptLoadEvents: [];
    setBeforeScriptLoadEvents: (prevState: []) => [];
    track: (...args: any[]) => any;
}

const TrackingContext = createContext({} as ITrackingContext);

export const TrackingProvider = ({ children }: { children: ReactNode }) => {
    const [isGtagLoaded, setIsGtagLoaded] = useState(false);
    const [beforeScriptLoadEvents, setBeforeScriptLoadEvents] = useState<any[]>([]);

    const addBeforeScriptLoadEvent = (event: any) => {
        setBeforeScriptLoadEvents((prevState) => [
            ...prevState,
            event,
        ])
    }

    const track = (...event: any) => {
        isGtagLoaded ? window.gtagConsent(...event) : addBeforeScriptLoadEvent(event);
    }

    useEffect(() => {
        if (isGtagLoaded) {
            beforeScriptLoadEvents.forEach((event) => track(...event))
        }
    }, [isGtagLoaded]);

    return (
        <TrackingContext.Provider value={{
            isGtagLoaded,
            setIsGtagLoaded,
            track,
        } as ITrackingContext}>
            {children}
        </TrackingContext.Provider>
    )
}

export const useTracking = () => useContext(TrackingContext);