'use client'

import {GrowthBook} from "@growthbook/growthbook";
import {PropsWithChildren, useEffect, useMemo} from "react";
import getGrowthbookConfig from "@/app/lib/Growthbook/getGrowthbookConfig";
import Cookies from "js-cookie";
import {GrowthBookProvider} from "@growthbook/growthbook-react";
import {CookiesEnum} from "@/app/enums/Cookies";
import {getDeviceType} from "@/app/utils/device";

export default function GrowthbookClient({ children }: PropsWithChildren) {
    const uuidCookie = Cookies.get(CookiesEnum.GBUUID);

    const gb = useMemo(() => {
        return new GrowthBook(getGrowthbookConfig(uuidCookie));
    }, [])
    useEffect(() => {
        const initGb =  async function () {
            await gb.init({
                streaming: true,
            })
        }

        initGb().then(() => {
            gb.setAttributes({
                id: uuidCookie,
                clientId: uuidCookie,
                anonymousId: uuidCookie,
                deviceType: getDeviceType(),
            })
        })
    }, [gb]);

    return <GrowthBookProvider growthbook={gb}>
        {children}
    </GrowthBookProvider>
}
